export interface TwilioError {
    code: number;
    message: string;
    twilioError?: {
        code: number;
        causes: string[];
        description: string;
        explanation: string;
        solutions: string[];
        message: string;
        stack: string;
        originalError?: Error;
    };
}

// https://www.twilio.com/docs/api/errors
const ACCESS_TOKEN_INVALID = 20101;
const ACCESS_TOKEN_EXPIRED = 20104;
const ACCESS_TOKEN_NOT_YET_VALID = 20105;
const ACCESS_TOKEN_INVALID_SIGNATURE = 20107;

export const twilioTokenErrorCodes = [
    ACCESS_TOKEN_INVALID,
    ACCESS_TOKEN_EXPIRED,
    ACCESS_TOKEN_NOT_YET_VALID,
    ACCESS_TOKEN_INVALID_SIGNATURE,
];

//https: www.twilio.com/docs/voice/sdks/error-codes
export const GENERAL_ERROR = 31000;
export const CONNECTION_DECLINED = 31002;
export const POOR_INTERNET_CONNECTION = 31003;
export const NO_INTERNET = 31005;
export const INVALID_CONTACT_NAME = 31105;
export const MICROPHONE_NOT_FOUND = 31201;
export const INVALID_JWT = 31204;
export const EXPIRED_JWT = 31205;
export const AUTHENTICATION_EXPIRED = 31207;
export const MICROPHONE_NOT_ENABLED = 31208;
export const NO_TRANSPORT_AVAILABLE = 31009;

export const twillioVoiceErrorCodeMappings: Record<number, string> = {
    [GENERAL_ERROR]: "General Error encountered",
    [CONNECTION_DECLINED]: "Connection Declined",
    [POOR_INTERNET_CONNECTION]: "Poor or intermittent Internet connection",
    [NO_INTERNET]: "No internet",
    [INVALID_CONTACT_NAME]: "Invalid contact name",
    [MICROPHONE_NOT_FOUND]: "Microphone not found",
    [INVALID_JWT]: "Invalid authentication token. Please logout and login again",
    [EXPIRED_JWT]: "Authentication expired. Please logout and login again",
    [AUTHENTICATION_EXPIRED]: "Authentication expired. Please logout and login again",
    [MICROPHONE_NOT_ENABLED]: "Microphone has not been enabled",
    [NO_TRANSPORT_AVAILABLE]: "No transport available to send or receive messages",
};
