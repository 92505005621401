import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import {
    getPrerecordedVoiceMessagesQueryWithTracking,
    getPrerecordedVoiceMessagesForUserQueryWithTracking,
    getPreferedAgentVMDropsQueryWithTracking,
    getQueryablePrerecordedVoiceMessagesWithTracking,
    type PrerecordedVoiceMessagesResponse,
    type PrerecordedVoiceMessagesForUserResponse,
    type PreferedAgentVMDropResponse,
    type QueryablePrerecordedVoiceMessagesResponse,
} from "../../queries";

import type { PrerecordedVoiceMessage, RecordingType } from "Types/Recordings";

type BasicHookProps = {
    invokedLocation?: string;
};

type PreRecrodedHookProps = BasicHookProps & {
    type?: RecordingType;
};
type PreferredHookProps = BasicHookProps & {
    contactPhone: string;
    campaignId?: string;
};
type QueryableHookProps = BasicHookProps & {
    type?: RecordingType;
    phoneCallCampaignId?: string;
};

const emptyMessage: Array<PrerecordedVoiceMessage> = [];

export const DEFAULT_HOOK_QUERY_LOCATION = "PrerecordedVoiceMessagesHookQuery";

export const useAgentRecordingsData = ({ invokedLocation = DEFAULT_HOOK_QUERY_LOCATION }: BasicHookProps) => {
    const query = useMemo(() => {
        return getPrerecordedVoiceMessagesForUserQueryWithTracking(invokedLocation);
    }, [invokedLocation]);

    const { loading, data, refetch } = useQuery<PrerecordedVoiceMessagesForUserResponse>(query, {
        fetchPolicy: "no-cache",
    });

    return {
        loading,
        data: data?.getPrerecordedVoiceMessagesForUser || emptyMessage,
        refetch,
    };
};

export const usePrerecordedVoiceMessageData = ({
    invokedLocation = DEFAULT_HOOK_QUERY_LOCATION,
    type,
}: PreRecrodedHookProps) => {
    const query = useMemo(() => {
        return getPrerecordedVoiceMessagesQueryWithTracking(invokedLocation);
    }, [invokedLocation]);

    const { loading, data, refetch } = useQuery<PrerecordedVoiceMessagesResponse>(query, {
        fetchPolicy: "no-cache",
        variables: type ? { type } : undefined,
    });

    return {
        loading,
        data: data?.getPrerecordedVoiceMessages || emptyMessage,
        refetch,
    };
};

export const usePreferedAgentVMDropsData = ({
    invokedLocation = DEFAULT_HOOK_QUERY_LOCATION,
    contactPhone,
    campaignId,
}: PreferredHookProps) => {
    const emptyState = {
        list: [],
        defaultRecording: null,
    };
    const query = getPreferedAgentVMDropsQueryWithTracking(invokedLocation);
    const { loading, data, refetch } = useQuery<PreferedAgentVMDropResponse>(query, {
        fetchPolicy: "no-cache",
        variables: {
            contactPhone,
            // conditionally add campaignId to the query
            ...(campaignId && { campaignId }),
        },
    });

    return {
        loading,
        data: data?.getPreselectedVMDropNoDuplicates || emptyState,
        refetch,
    };
};

export const useCampaignAllAgentsRecordingsData = ({
    invokedLocation = DEFAULT_HOOK_QUERY_LOCATION,
    phoneCallCampaignId,
    type,
}: QueryableHookProps) => {
    const queryParams = useMemo(() => {
        return {
            filterBy: [
                ...(type ? [{ key: "type", operator: "is", value: type }] : []),
                ...(phoneCallCampaignId
                    ? [{ key: "phoneCallCampaignId", operator: "is", value: phoneCallCampaignId }]
                    : []),
            ],
        };
    }, [phoneCallCampaignId, type]);

    const query = useMemo(() => {
        return getQueryablePrerecordedVoiceMessagesWithTracking(invokedLocation);
    }, [invokedLocation]);

    const { loading, data, refetch } = useQuery<QueryablePrerecordedVoiceMessagesResponse>(query, {
        skip: !phoneCallCampaignId,
        fetchPolicy: "no-cache",
        variables: {
            queryParams,
        },
    });

    return {
        loading,
        data: data?.getPrerecordedVoiceMessagesByQuery || emptyMessage,
        refetch,
    };
};
