export interface FilterParams<T = string | boolean | any[] | null> {
    operator?: OperatorType;
    key: string;
    value: T | T[] | null;
}
export interface QueryParams<T = string | boolean | any[] | null> {
    orderBy: Array<SortParams> | null;
    filterBy: Array<FilterParams<T>> | null;
    page: number;
    pageSize: number;
    profileId?: string;
}

//  TODO: add generics to specify the type of the value
export interface SortParams {
    key: string;
    value: string | number | boolean | any[];
}

export const queryParamsProps = ["orderBy", "filterBy", "page", "pageSize", "profileId"];

export enum OperatorType {
    CONTAINS = "contains",
    IS = "is",
    IN = "in",
    OVERLAPS = "overlaps",
    STARTS_WITH = "starts_with",
    IN_JSON_ARRAY = "in_json_array",
}

export const filterkeySearch = "search";

export function getAdditionalFiltersFromQueryParams(
    filters: FilterParams[] | null | undefined,
    additionalFilters: any[]
): FilterParams[] {
    return filters?.filter((i: FilterParams) => [filterkeySearch, ...additionalFilters].includes(i.key)) ?? [];
}

export function getSearchFiltersFromQueryParams(filters: FilterParams[] | null | undefined): FilterParams[] {
    return filters?.filter((i: FilterParams) => i.key == filterkeySearch) ?? [];
}

export function getNonSearchFiltersFromQueryParams(filters: FilterParams[] | null | undefined): FilterParams[] {
    return filters?.filter((i: FilterParams) => i.key !== filterkeySearch) ?? [];
}
