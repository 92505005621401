import { FieldType } from "@regal-voice/shared-types";
import { RcFile } from "antd/lib/upload/interface";

import { Journey, NodeTriggerType } from "./Journey";

export enum JoinType {
    ALL = "AND",
    ANY = "OR",
    NONE = "NOT",
}

export type ConditionalType = "contactAttribute" | "customEvent" | "regalVoiceEvent" | "triggeringEvent";
export type NamedConditionalTypes = Array<{
    value: ConditionalType | NodeTriggerType;
    name: string;
    hideCustomFields?: boolean;
}>;

export interface EventProperty {
    propertyName?: string;
    propertyOperator: string;
    propertyValue?: string | number;
    propertyType?: FieldType;
    propertyFieldId?: string;
    relativeDateValue?: string;
    dateComparisonType?: string;
}
export interface ConditionEvent {
    conditionalType?: ConditionalType | NodeTriggerType;
    eventName?: string;
    eventOperator?: string;
    eventType?: string;
    filters: EventProperty[];
    lastEventOnly: boolean;
}
export interface DynamicCondition {
    name?: string;
    joinType?: JoinType;
    conditions: ConditionEvent[];
}
export interface ConditionList {
    dynamicConditions: DynamicCondition[];
    joinType: JoinType;
}
export interface SegmentCondition {
    field?: string;
    comparator?: string;
    value?: string | string[] | boolean | { form: string; to: string };
}

export interface Segment extends ConditionList {
    readonly id: string;
    readonly friendlyId?: number;
    name: string;
    conditions: SegmentCondition[];
    type: SegmentType;
    updateOnlyExisting: boolean;
    triggerJourney: boolean;
    description?: string;
    file?: RcFile;
    filePath?: string;
    includeCsvDataTypeRow?: boolean;
    journeysToTrigger?: Partial<Journey>[];
    stats?: SegmentStats;
    isHidden?: boolean;
    readonly count?: number;
    readonly createdAt?: Date;
    readonly createdBy?: string;
    readonly updatedAt?: Date;
    readonly updatedBy?: string;
}

export interface SegmentStats {
    error: number;
    updated: number;
    skipped: number;
    total: number;
    finished: boolean;
    upload_failed: boolean;
}

export enum SegmentType {
    DYNAMIC = "dynamic",
    STATIC = "static",
}

// TODO: later map the operators based on type inference
export enum ConditionOperators {
    IS_IN = "IN",
    EQUALS = "=",
    NOT_EQUALS = "!=",
    IS_NOT_IN = "NOT IN",
    IS = "IS", // exclusive for boolean operations
    GREATER_THAN = ">",
    GREATER_OR_EQUAL = ">=",
    LESS_THAN = "<",
    LESS_OR_EQUAL = "<=",
    BETWEEN = "BETWEEN",
    NOT_BETWEEN = "NOT BETWEEN",
}

export interface FieldLookup {
    label?: string;
    value: string;
}

export enum SelectionType {
    STRING = "single",
    MULTI_SELECTION = "multi",
    BOOLEAN_SELECTION = "bool",
    NUMERIC = "numeric",
    RANGE = "range",
}

export const operatorSelectionMapping = [
    {
        value: ConditionOperators.BETWEEN,
        display: "IS BETWEEN",
        type: SelectionType.RANGE,
    },
    {
        value: ConditionOperators.NOT_BETWEEN,
        display: "IS NOT BETWEEN",
        type: SelectionType.RANGE,
    },
    {
        value: ConditionOperators.EQUALS,
        display: "EQUALS",
        type: SelectionType.STRING,
    },
    {
        value: ConditionOperators.NOT_EQUALS,
        display: "!=",
        type: SelectionType.STRING,
    },
    {
        value: ConditionOperators.IS_IN,
        display: "IS IN",
        type: SelectionType.MULTI_SELECTION,
    },
    {
        value: ConditionOperators.IS_NOT_IN,
        display: "IS NOT IN",
        type: SelectionType.MULTI_SELECTION,
    },
    {
        value: ConditionOperators.IS,
        display: "IS",
        type: SelectionType.BOOLEAN_SELECTION,
    },
    {
        value: ConditionOperators.GREATER_THAN,
        display: "&gt;",
        type: SelectionType.NUMERIC,
    },
    {
        value: ConditionOperators.GREATER_OR_EQUAL,
        display: "&gt;=",
        type: SelectionType.NUMERIC,
    },
    {
        value: ConditionOperators.LESS_THAN,
        display: "&gt;=",
        type: SelectionType.NUMERIC,
    },
    {
        value: ConditionOperators.LESS_OR_EQUAL,
        display: "&lt;=",
        type: SelectionType.NUMERIC,
    },
];
