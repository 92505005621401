import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import { pick } from "lodash";
import { useDispatch } from "react-redux";
import { AnyAction, Middleware } from "redux";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    createMigrate,
    PersistedState,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { ThunkDispatch } from "redux-thunk";

import { getLogger } from "Services/LoggingService";
import userReducer from "Services/state/users/UserSlice";

import agentInformation from "./agent/AgentInformationSlice";
import agentDesktopUI from "./agent/desktopUI/AgentDesktopUISlice";
import emailUIPersist from "./agent/desktopUI/email/EmailUIPersistSlice";
import emailUI from "./agent/desktopUI/email/EmailUISlice";
import brand from "./brand/BrandProvider";
import brandedPhoneNumbers from "./branded-phone-numbers/IndexProvider";
import conferenceSnapshot from "./conferences/ConferenceSnapshotSlice";
import conference from "./conferences/IndexProvider";
import contactAttributes from "./contact-attributes";
import contacts from "./contacts/IndexProvider";
import conversations from "./conversations/IndexProvider";
import customObjects from "./custom-objects/IndexProvider";
import { globalMessageMiddleware } from "./global-messages/GlobalMessages";
import inputPersist from "./input-persist/IndexProvider";
import IVRNodeData from "./ivr/IVRNodeDataStateSlice";
import journey from "./journeys/IndexProvider";
import managerAction from "./manager-action/IndexProvider";
import savedViews from "./saved-views/SavedViewsProvider";
import tasks from "./tasks/IndexProvider";
import transcriptions from "./transcriptions/IndexProvider";

const root = sessionStorage.getItem("persist:root") ? JSON.parse(sessionStorage.getItem("persist:root") || "") : {};
const userInformation = root?.userInformation;

const didManuallyLogOut = userInformation && JSON.parse(userInformation).didManuallyLogOut;

if (didManuallyLogOut) {
    storage.removeItem("persist:root");
}

// Each method handles migrating a previous shape of persisted redux state to a new version.
// The key is the version # that is being migrated *to*
// redux-persist store versions start at -1, so 0 represents our second shape and first migration
const persistMigrationHandlers = {
    0: (state: PersistedState) => {
        const {
            inputPersist: { activeContactPanelTab, taskSummaries, agentInput },
        } = state as unknown as RootReducerShape & {
            inputPersist: {
                agentInput: Record<string, string>;
                taskSummaries: Record<string, RVTask>;
                activeContactPanelTab: Record<string, RVTask>;
            };
        };
        return {
            ...state,
            inputPersist: {
                textMessageInput: agentInput,
                emailInput: {},
                taskSummaries: taskSummaries,
                activeContactPanelTab: activeContactPanelTab,
            },
        } as PersistedState;
    },
    1: (state: PersistedState) => {
        const {
            inputPersist: {
                textMessageInput,
                emailInput,
                textMessageLexicalInput,
                emailLexicalInput,
                taskSummaries,
                activeContactPanelTab,
            },
        } = state as unknown as RootReducerShape;
        return {
            ...state,
            inputPersist: {
                textMessageInput: textMessageInput,
                textMessageLexicalInput: textMessageLexicalInput,
                emailInput: emailInput,
                emailLexicalInput: emailLexicalInput,
                taskSummaries: taskSummaries,
                activeContactPanelTab: activeContactPanelTab,
                activityFeedConversationFilter: {},
            },
        } as PersistedState;
    },
};

const persistConfig = {
    key: "root",
    // Session storage - could consider something like https://stackoverflow.com/questions/56099291/how-to-persist-to-both-localstorage-and-sessionstorage-at-the-same-time-using-re
    // if this becomes problematic
    storage,
    blacklist: [
        "brand",
        "conversations",
        "tasks",
        "contacts",
        "conference",
        "conferenceSnapshot",
        "journey",
        "IVRNodeData",
        "managerAction",
        "emailUI",
    ],
    version: 1,
    migrate: createMigrate(persistMigrationHandlers),
};

const persistedConference = persistReducer(
    { key: "conference", storage, whitelist: ["connectionQuality"] },
    conference
);

export type RootReducerShape = {
    agentDesktopUI: ReturnType<typeof agentDesktopUI>;
    agentInformation: ReturnType<typeof agentInformation>;
    brand: ReturnType<typeof brand>;
    brandedPhoneNumbers: ReturnType<typeof brandedPhoneNumbers>;
    conference: ReturnType<typeof persistedConference>;
    conferenceSnapshot: ReturnType<typeof conferenceSnapshot>;
    contactAttributes: ReturnType<typeof contactAttributes>;
    contacts: ReturnType<typeof contacts>;
    conversations: ReturnType<typeof conversations>;
    customObjects: ReturnType<typeof customObjects>;
    emailUI: ReturnType<typeof emailUI>;
    emailUIPersist: ReturnType<typeof emailUIPersist>;
    inputPersist: ReturnType<typeof inputPersist>;
    journey: ReturnType<typeof journey>;
    IVRNodeData: ReturnType<typeof IVRNodeData>;
    managerAction: ReturnType<typeof managerAction>;
    savedViews: ReturnType<typeof savedViews>;
    tasks: ReturnType<typeof tasks>;
    transcriptions: ReturnType<typeof transcriptions>;
    userInformation: ReturnType<typeof userReducer>;
};

export const reduxRootReducer = combineReducers({
    agentDesktopUI,
    agentInformation,
    brand,
    brandedPhoneNumbers,
    conference: persistedConference,
    conferenceSnapshot,
    contactAttributes,
    contacts,
    conversations,
    customObjects,
    emailUI,
    emailUIPersist,
    inputPersist,
    journey,
    IVRNodeData,
    managerAction,
    savedViews,
    tasks,
    transcriptions,
    userInformation: userReducer,
});

const persistedReducer = persistReducer(persistConfig, reduxRootReducer);

const logger = getLogger("Redux");

const whitelist = [
    "brand",
    "agentInformation",
    "conversations",
    "conference",
    "conferenceSnapshot",
    "tasks",
    "inputPersist",
    "managerAction",
];

const reduxLogger: Middleware<any, RootReducerShape> = (store: any) => (next) => (action) => {
    const result = next(action);
    const state = pick(store.getState(), whitelist);
    logger.debug(action.type, { action, state });
    return result;
};

export const reduxStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(reduxLogger, globalMessageMiddleware),
    devTools: true,
});

export type RVStore = typeof reduxStore;
export type RVDispatch = typeof reduxStore.dispatch;
export const useRVDispatch: () => RVDispatch = useDispatch;

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AsyncThunkStore = { state: RootState; dispatch: RVDispatch };
export type ThunkType = (dispatch: RVDispatch, getState: () => RootState) => void;
export type ThunkAction<
    R, // Return type of the thunk function
    S, // state type used by getState
    E, // any "extra argument" injected into the thunk
    A extends AnyAction // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R;
