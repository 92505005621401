import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useFlags } from "Services/FeatureFlagService";
import { updateConferenceSnapshot } from "Services/state/conferences/ConferenceSnapshotSlice";
import { useRVDispatch } from "Services/state/Storage";

function ConferenceStateSSE() {
    const dispatch = useRVDispatch();
    useServerSentEvent("sse", "conference.state", (state) => {
        dispatch(updateConferenceSnapshot(state));
    });

    return null;
}

export default function SSEListeners() {
    const { rolloutUseConferenceStateSse } = useFlags();
    return <>{rolloutUseConferenceStateSse && <ConferenceStateSSE />}</>;
}
