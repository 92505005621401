import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useRVDispatch } from "Services/state/Storage";
import { updateAutocomplete } from "Services/state/tasks/TasksToAutocompleteSlice";

export function useAutocompleteListener(): void {
    const dispatch = useRVDispatch();
    useServerSentEvent("sse", "task.updatedAutocomplete", ({ originalTaskSid: taskSid, ...payload }) => {
        if (payload.autocompleteStartTime === null) {
            dispatch(
                updateAutocomplete({
                    taskSid,
                    clear: true,
                })
            );
        } else {
            dispatch(
                updateAutocomplete({
                    taskSid,
                    ...payload,
                })
            );
        }
    });
}
