import { ReactEventHandler, useCallback, useEffect, useMemo } from "react";

import { NotificationsConfigEventNames } from "@regal-voice/shared-types/lib/entities/Brand";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useAudio } from "react-use";

import {
    __msToWaitForSameEventNotifications,
    useAudioNotifications,
} from "App/main/AudioNotificationProvider/AudioNotificationProvider";
import { getLogger } from "Services/LoggingService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";

const logger = getLogger("Revamped Audio Notifications");

type PreloadedAudioPlayerProps = {
    src: string;
    loop?: boolean;
    attribute?: string;
    triggeringEvent: NotificationsConfigEventNames;
    taskTitle: string;
};

/**
 * Loads a particular audio notification into the DOM. The player handles its own debouncing to prevent spammy audio.
 * Stopping the audio has the option to only stop if the audio is looping, which is mostly the scenario we care about.
 * It handles stopping itself if the user goes on an active call.
 */
export function PreloadedAudioPlayer({
    src,
    loop = false,
    attribute,
    triggeringEvent,
    taskTitle,
}: PreloadedAudioPlayerProps) {
    const onActiveCall = useSelector(selectOnActiveCall);
    const { registerAudioPlayer } = useAudioNotifications();

    const onAudioError = useCallback<ReactEventHandler<HTMLAudioElement>>((evt) => {
        logger.error("Audio notification error", { error: "error" in evt.target ? evt.target.error : evt });
    }, []);

    const srcWithCacheBust = useMemo(() => `${src}?cache_bust=${Date.now()}`, [src]);

    const [audio, , { play, pause, seek }] = useAudio({
        src: srcWithCacheBust,
        loop,
        preload: "auto",
        autoPlay: false,
        crossOrigin: "anonymous",
        onError: onAudioError,
    });
    const isAutoAnswer = attribute === "autoAnswer";

    const stopAudio = useCallback(
        (checkForLoop: boolean) => {
            if (checkForLoop && !loop) {
                return;
            }
            logger.log("Stopping audio", { triggeringEvent, taskTitle, attribute, checkForLoop, loop });
            pause();
            seek(0);
        },
        // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loop, triggeringEvent, taskTitle, attribute]
    );

    const playAudio = useMemo(
        () =>
            debounce(
                () => {
                    logger.log("Playing audio", { triggeringEvent, taskTitle, attribute, loop });
                    play();
                },
                __msToWaitForSameEventNotifications,
                { leading: true }
            ),
        // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [attribute, loop, taskTitle, triggeringEvent]
    );

    useEffect(() => {
        // only works for when a call gets connected on the current tab
        if (onActiveCall && !isAutoAnswer) {
            stopAudio(true);
        }
    }, [attribute, onActiveCall, isAutoAnswer, stopAudio, taskTitle, triggeringEvent]);

    useEffect(() => {
        const unregister = registerAudioPlayer({
            taskTitle,
            triggeringEvent,
            play: playAudio,
            isAutoAnswer,
            attribute,
            stop: stopAudio,
        });
        return unregister;
    }, [registerAudioPlayer, taskTitle, triggeringEvent, isAutoAnswer, attribute, loop, stopAudio, playAudio]);

    return audio;
}
