import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActivityFeedConversationFilter } from "Pages/agentDesktop/agentInterface/ActivityView/Header/ActivityFeedFilter/ConversationFilter";
import { TaskSummary } from "Pages/agentDesktop/agentInterface/ActivityView/Header/TaskSummary/TaskSummaryHooks";

/**
 * Dictionary as a const to be able to use the keys as index, and because they are more
 * friendly than enums.
 *
 * @see https://www.youtube.com/watch?v=0fTdCSH_QEU
 */

export const InputPersistNamespaces = {
    TEXT_MESSAGE_INPUT: "textMessageInput",
    TEXT_MESSAGE_LEXICAL_INPUT: "textMessageLexicalInput",
    EMAIL_INPUT: "emailInput",
    EMAIL_LEXICAL_INPUT: "emailLexicalInput",
    TASK_SUMMARIES: "taskSummaries",
    ACTIVE_CONTACT_PANEL_TAB: "activeContactPanelTab",
    ACTIVITY_FEED_CONVERSATION_FILTER: "activityFeedConversationFilter",
} as const;

export type InputPersistNamespace = (typeof InputPersistNamespaces)[keyof typeof InputPersistNamespaces];

// A simple map between the namespace and the data type

export type DataByNamespace = {
    [InputPersistNamespaces.TEXT_MESSAGE_INPUT]: string;
    [InputPersistNamespaces.TEXT_MESSAGE_LEXICAL_INPUT]: string;
    [InputPersistNamespaces.EMAIL_INPUT]: string;
    [InputPersistNamespaces.EMAIL_LEXICAL_INPUT]: string;
    [InputPersistNamespaces.TASK_SUMMARIES]: TaskSummary;
    [InputPersistNamespaces.ACTIVE_CONTACT_PANEL_TAB]: string;
    [InputPersistNamespaces.ACTIVITY_FEED_CONVERSATION_FILTER]: ActivityFeedConversationFilter;
};

// Generate the state dynamically based on the namespace and the data type

export type InputPersistState = {
    [namespace in InputPersistNamespace]: Record<string, DataByNamespace[namespace]>;
};

const initialState: InputPersistState = {
    textMessageInput: {},
    textMessageLexicalInput: {},
    emailInput: {},
    emailLexicalInput: {},
    taskSummaries: {},
    activeContactPanelTab: {},
    activityFeedConversationFilter: {},
};

const inputPersistSlice = createSlice({
    name: "inputPersist",
    initialState,
    reducers: {
        setUserInput: (
            state,
            action: PayloadAction<{
                namespace: string;
                key: string;
                value: string | TaskSummary | ActivityFeedConversationFilter;
            }>
        ) => {
            // @ts-expect-error fix types
            state[action.payload.namespace][action.payload.key] = action.payload.value;
        },
        clearUserInput: (state, action: PayloadAction<{ namespace: string; key: string }>) => {
            // @ts-expect-error fix types
            delete state[action.payload.namespace][action.payload.key];
        },
        resetUserInput: (state, action: PayloadAction<{ namespace: string }>) => {
            // @ts-expect-error fix types
            state[action.payload.namespace] = {};
        },
    },
});

export const { setUserInput, clearUserInput, resetUserInput } = inputPersistSlice.actions;

export default inputPersistSlice.reducer;
